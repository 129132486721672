<!-- eslint-disable vue/no-v-html -->
<template>
    <section
        class="content-author"
        :class="{'simple': simpleView}"
    >
        <div
            class="content-author__content-container"
            :class="{'simple': simpleView}"
        >
            <div
                v-if="author.image"
                class="content-author__image"
            >
                <img
                    :src="author.image"
                    :alt="author.name"
                    loading="lazy"
                >
            </div>

            <div
                class="content-author__content"
                :class="{'simple': simpleView}"
            >
                <div class="content-author__content--header">
                    <div
                        class="header__socials"
                        :class="{'simple': simpleView}"
                    >
                        <a
                            v-if="author.email"
                            class="button-email"
                            :href="`mailto:${author.email}`"
                            target="_blank"
                        >
                            <span class="sr-only">{{ translations.email }}</span>
                        </a>

                        <a
                            v-if="author.linkedin"
                            class="button-linkedin"
                            :href="author.linkedin"
                            target="_blank"
                        >
                            <span class="sr-only">{{ translations.linkedin }}</span>
                        </a>

                        <a
                            v-if="author.twitter"
                            class="button-twitter"
                            :href="author.twitter"
                            target="_blank"
                        >
                            <span class="sr-only">{{ translations.twitter }}</span>
                        </a>

                        <a
                            v-if="author.website"
                            class="button-website"
                            :href="author.website"
                            target="_blank"
                        >
                            <span class="sr-only">{{ translations.website }}</span>
                        </a>
                    </div>

                    <div class="header__title">
                        <span v-if="!simpleView">
                            {{ translations.writtenBy }}&nbsp;
                        </span>

                        <a
                            v-if="author.url"
                            :href="author.url"
                        >
                            {{ author.name }}
                        </a>

                        <span v-else>
                            {{ author.name }}
                        </span>

                        <span v-if="author.jobTitle">, {{ author.jobTitle }} </span>
                    </div>
                </div>

                <div
                    v-if="author.shortenedbio"
                    class="content-author__content--shortbio"
                    :class="{'simple': simpleView}"
                    v-html="author.shortenedbio"
                ></div>

                <div
                    v-else-if="author.bio"
                    class="content-author__content--bio"
                    :class="{'simple': simpleView}"
                    v-html="author.bio"
                ></div>
            </div>
        </div>

        <div
            v-if="contributors.length"
            class="content-author__contributors"
        >
            <span class="contributors__title contributor">
                {{ translations.contributors }}
            </span>

            <figure
                v-for="contributor in contributors"
                :key="contributor.id"
                class="contributors__item"
            >
                <img
                    v-if="contributor.image"
                    :src="contributor.image"
                    :alt="contributor.name"
                    loading="lazy"
                >
                <figcaption>
                    <a :href="contributor.url">
                        {{ contributor.name }}
                    </a>
                </figcaption>
            </figure>
        </div>

        <div
            v-if="expertReviewers.length"
            class="content-author__contributors"
        >
            <span class="contributors__title expert">
                {{ translations.expertReviewers }}
            </span>

            <figure
                v-for="expertReviewer in expertReviewers"
                :key="expertReviewer.id"
                class="contributors__item"
            >
                <img
                    v-if="expertReviewer.image"
                    :src="expertReviewer.image"
                    :alt="expertReviewer.name"
                    loading="lazy"
                >
                <figcaption>
                    <a :href="expertReviewer.url">
                        {{ expertReviewer.name }}
                    </a>
                </figcaption>
            </figure>
        </div>

        <div
            v-if="editors.length"
            class="content-author__contributors"
        >
            <span class="contributors__title editor">
                {{ translations.editor }}
            </span>

            <figure
                v-for="editor in editors"
                :key="editor.id"
                class="contributors__item"
            >
                <img
                    v-if="editor.image"
                    :src="editor.image"
                    :alt="editor.name"
                    loading="lazy"
                >
                <figcaption>
                    <a :href="editor.url">
                        {{ editor.name }}
                    </a>
                </figcaption>
            </figure>
        </div>
    </section>
</template>

<script setup>
import { usePage } from '@inertiajs/vue3';

const page = usePage();

defineProps({
    simpleView: {
        type: Boolean,
        required: false,
        default: false,
    },
    author: {
        type: Object,
        required: false,
        default: null,
    },
    contributors: {
        type: Array,
        required: false,
        default: () => [],
    },
    expertReviewers: {
        type: Array,
        required: false,
        default: () => [],
    },
    editors: {
        type: Array,
        required: false,
        default: () => [],
    },
});

const translations = page.props.settings.translations.author;
</script>

<style lang="scss" scoped>
@use 'watson-organism-content-author';
</style>
